import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPHCMS_CONTENT_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  const token = process.env.NEXT_PUBLIC_GRAPHCMS_PERMANENT_TOKEN;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export default new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

interface ImageOptions {
  width?: number;
  height?: number;
  file?: string;
}

export const getImageUrl = (request: string, options: ImageOptions) => {
  return `https://media.graphcms.com/resize=fit:clip,height:${
    options.height || 500
  },width:${options.width || 500}/output=format:${
    options.file || "webp"
  }/${request}`;
};

import { createTheme, ThemeOptions } from "@material-ui/core/styles";
import { Shadows } from "@material-ui/core/styles/shadows";

const common: ThemeOptions = {
  typography: {
    fontFamily: ['"Noto Sans JP"', "sans-serif"].join(", "),
    h4: {
      fontWeight: 600,
      fontFamily: ['"Shippori Mincho B1"', "serif"].join(", "),
    },
    h5: {
      fontWeight: 600,
      fontFamily: ['"Shippori Mincho B1"', "serif"].join(", "),
    },
    h6: {
      fontWeight: 600,
      fontFamily: ['"Shippori Mincho B1"', "serif"].join(", "),
    },
    subtitle1: {
      fontWeight: 600,
      fontFamily: ['"Shippori Mincho B1"', "serif"].join(", "),
    },
    subtitle2: {
      fontWeight: 600,
      fontFamily: ['"Shippori Mincho B1"', "serif"].join(", "),
    },
    body1: {
      fontWeight: 300,
    },
    body2: {
      fontWeight: 300,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 4,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 4,
      },
    },
    /* MuiListSubheader: {
      sticky: {
        backgroundColor: "#fff",
      },
    }, */
    MuiPopover: {
      paper: {
        backdropFilter: "blur(12px)",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 4,
      },
    },
    MuiCard: {
      root: {
        borderRadius: 4,
      },
    },
    /* MuiCssBaseline: {
    '@global': {
      em: {
        backgroundColor: '#d74b22',
        color: '#fff',
        borderRadius: 8,
      },
      '::selection': {
        backgroundColor: '#d74b22',
        color: '#fff',
      },
    },
  },*/
  },
  shadows: Array(25).fill("none") as Shadows,
};

const dark: ThemeOptions = {
  palette: {
    type: "dark",
    primary: {
      main: "#afe4ff",
    },
    secondary: {
      main: "#ef663e",
    },
    background: {},
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      // primary: "rgba(0, 0, 0, 0.76)",
      // hint: "#fff",
    },
  },
};
export const darkTheme = createTheme({ ...common, ...dark });

const light: ThemeOptions = {
  palette: {
    type: "light",
    primary: {
      main: "#296A8B",
    },
    secondary: {
      main: "#ef663e",
    },
    background: {
      default: "#f9f9f9",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: "rgba(0, 0, 0, 0.76)",
      // hint: "#296A8B",
    },
  },
};
export const lightTheme = createTheme({ ...common, ...light });

import "@Styles/globals.css";
import React from "react";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import Head from "next/head";
import { appWithTranslation } from "next-i18next";
import useDarkMode from "use-dark-mode";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ApolloProvider } from "@apollo/client";

import { darkTheme, lightTheme } from "@Components/common/theme";
import firebase from "@Config/firebaseInit";
import client from "@Config/graphCMSInit";

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const darkMode = useDarkMode(prefersDarkMode);

  React.useEffect(() => {
    firebase.analytics().logEvent("next_app");
    firebase.analytics().logEvent(router.asPath);
  });

  /* React.useEffect(() => {
    const handleRouteChange = (url: any) => {
      firebase.analytics().logEvent(url);
      console.log("e", url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]); */

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <React.Fragment>
      <Head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="initial-scale=1,maximum-scale=1,user-scalable=no"
        />
        <link rel="alternate" hrefLang="en" href="https://tokiyado.net/en" />
        <link
          rel="alternate"
          hrefLang="x-default"
          href="https://tokiyado.net/"
        />
        <meta name="theme-color" content="#296A8B" />
        {router.locale === "ja" ? (
          <link rel="manifest" href="/ja.manifest.json" />
        ) : (
          <link rel="manifest" href="/en.manifest.json" />
        )}
        <link
          rel="apple-touch-icon"
          href="/icons/maskable/maskable_icon_x192.png"
        />
        <link
          rel="apple-touch-icon"
          href="/icons/maskable/maskable_icon_x512.png"
          sizes="512x512"
        />

        <link rel="icon" type="image/png" href="/icons/icon_x192.png" />
        <link
          rel="icon"
          type="image/png"
          href="/icons/icon_x512.png"
          sizes="512x512"
        />
      </Head>

      <ThemeProvider theme={darkMode.value ? darkTheme : lightTheme}>
        <CssBaseline />
        <ApolloProvider client={client}>
          <Component {...pageProps} />
        </ApolloProvider>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default appWithTranslation(MyApp);
